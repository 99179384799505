{********************************************************************}
{                                                                    }
{ written by TMS Software                                            }
{            copyright (c) 2018 - 2024                               }
{            Email : info@tmssoftware.com                            }
{            Web : http://www.tmssoftware.com                        }
{                                                                    }
{ The source code is given as is. The author is not responsible      }
{ for any possible damage done due to the use of this code.          }
{ The complete source code remains property of the author and may    }
{ not be distributed, published, given or sold in any form as such.  }
{ No parts of the source code can be included in any other component }
{ or application without written authorization of the author.        }
{********************************************************************}

unit WEBLib.JSON;

interface

uses
  Classes, Web, JS, SysUtils;

type
  TJSONObject = class;
  TJSONString = class;

  TJSONAncestor = class(TObject)
  private
  protected
    function GetStrValue: string; virtual;
    procedure SetStrValue(const Value: string); virtual;
  public
    property Value: string read GetStrValue write SetStrValue;
  end;

  TJSONValue = class(TJSONAncestor)
  private
    fjv: JSValue;
    fjo: JSValue;
  protected
    function GetStrValue: string; override;
    property jv: JSValue read fjv write fjv;
    class function JSONValueFromJS(const AJSValue: JSValue): TJSONValue;
  public
    constructor Create(AJSValue: JSValue); overload;
    function ToString: string; override;
    function GetValue<T>(const APath: string = ''): T;
    function GetJSValue: JSValue;
  end;

  TJSONPair = class(TObject)
  private
    fjo: JSValue;
    fjv: TJSONValue;
    fjs: TJSONString;
  public
    constructor Create; overload;
    constructor Create(const Str: string; const Value: TJSONValue); overload;
    constructor Create(const Str: string; const Value: string); overload;
    destructor Destroy; override;
    property JsonValue: TJSONValue read fjv write fjv;
    property JsonString: TJSONString read fjs write fjs;
    function ToString: string; override;
  end;

  TJSONPairList = class(TList);

  TJSONObject = class(TJSONValue)
  private
    fjo: TJSObject;
    FMembers: TJSONPairList;
    function GetPair(const I: Integer): TJSONPair;
    function GetCount: Integer;
    function GetItem(const Index: Integer): TJSONValue;
    procedure SetValues(const Name: string; const Value: TJSONValue);
  protected
    property jo: TJSObject read fjo write fjo;
    procedure AddDescendant(const Descendent: TJSONPair); virtual;
  public
    constructor Create; overload;
    constructor Create(AObject: TJSObject); overload;
    destructor Destroy; override;
    property JSObject: TJSObject read fjo;
    class function ParseJSONValue(const data: string): TJSONValue;
    function GetJSONValue(Name: string): string;
    function Get(Name: string): TJSONPair; overload;
    function Get(Index: Integer): TJSONPair; overload;
    function GetValue(const Name: string): TJSONValue;
    procedure SetValue(const Name: string; AValue: string); overload;
    procedure SetValue(const Name: string; AValue: integer); overload;
    procedure SetValue(const Name: string; AValue: double); overload;
    property Items[const Index: Integer]: TJSONValue read GetItem;
    property Values[const Name: string]: TJSONValue read GetValue write SetValues;
    property Pairs[const Index: Integer]: TJSONPair read GetPair;
    property Count: Integer read GetCount;
    function AddPair(const Pair: TJSONPair): TJSONObject; overload;
    function AddPair(const Str: string; const Val: TJSONValue): TJSONObject; overload;
    function AddPair(const Str: string; const Val: string): TJSONObject; overload;
    function AddPair(const Str: string; const Val: Integer): TJSONObject; overload;
    function AddPair(const Str: string; const Val: Int64): TJSONObject; overload;
    function AddPair(const Str: string; const Val: Double): TJSONObject; overload;
    function RemovePair(const PairName: string): TJSONPair;

    function ToJSON: string;
    function ToString: string; override;
  end;

  TJSONString = class(TJSONValue)
  private
    FValue: string;
  protected
    function GetStrValue: string; override;
    procedure SetStrValue(const Value: string); override;
  public
    constructor Create(AString: string); overload;
  end;

  TJSONNumber = class(TJSONString)
  private
    FInt: Integer;
    FInt64: Int64;
    FDouble: Double;
  protected
    function GetStrValue: string; override;
    procedure SetStrValue(const Value: string); override;
  public
    constructor Create(ANumber: integer); overload;
    constructor Create(ANumber: Int64); overload;
    constructor Create(ANumber: double); overload;

    property AsInt: integer read FInt write FInt;
    property AsInt64: Int64 read FInt64 write FInt64;
    property AsDouble: Double read FDouble write FDouble;
    property AsFloat: Double read FDouble write FDouble;
  end;

  TJSONBool = class(TJSONValue)
  private
    FBool: boolean;
  protected
    procedure SetStrValue(const Value: string); override;
  public
    property AsBoolean: boolean read FBool write FBool;
  end;

  TJSONTrue = class(TJSONBool)
  protected
    function GetStrValue: string; override;
  public
    function ToString: string; override;
  end;

  TJSONFalse = class(TJSONBool)
  protected
    function GetStrValue: string; override;
  public
    function ToString: string; override;
  end;

  TJSONNull = class(TJSONValue)
  protected
    function GetStrValue: string; override;
  public
    function ToString: string; override;
  end;

  TJSONArray = class;

  TJSONArrayEnumerator = class
  private
    FJSONArray: TJSONArray;
    FPosition: Integer;
  public
    constructor Create(AJSONArray: TJSONArray); reintroduce;
    function GetCurrent: TJSONValue;
    function MoveNext: Boolean;
    property Current: TJSONValue read GetCurrent;
  end;

  TJSONArray = class(TJSONValue)
  private
    fja: TJSArray;
    function GetItem(index: integer): TJSONValue;
    procedure SetItem(index: integer; const Value: TJSONValue);
    function GetCount: integer; reintroduce;
    function GetJA: TJSArray;
  public
    constructor Create(AArray: TJSArray); overload;
    property ja: TJSArray read GetJA write fja;
    property Count: integer read GetCount;
    property Items[index: integer]: TJSONValue read GetItem write SetItem; default;
    function GetEnumerator: TJSONArrayEnumerator;
    procedure Add(const Element: string); overload;
    procedure Add(const Element: boolean); overload;
    procedure Add(const Element: integer); overload;
    procedure Add(const Element: double); overload;
    procedure Add(const Element: TJSONObject); overload;
    procedure Add(const Element: TJSONPair); overload;

    function ToJSON: string;
    function ToString: string; override;
  end;

  TJSON = class(TObject)
    function Parse(s: string): TJSONValue;
  end;

implementation

function JSONObjectToString(const v: JSValue): string; assembler;
asm
  return v+"";
end;

{ TJSON }

function TJSON.Parse(s: string): TJSONValue;
var
  O: TJSObject;
begin
  O := TJSJSON.parseObject(s);

  if isArray(O) then
  begin
    Result := TJSONArray.Create(TJSArray(O));
  end
  else
  begin
    Result := TJSONObject.Create(O);
  end;
end;

{ TJSONArray }

procedure TJSONArray.Add(const Element: string);
begin
  ja.push(Element);
end;

procedure TJSONArray.Add(const Element: integer);
begin
  ja.push(Element);
end;

procedure TJSONArray.Add(const Element: boolean);
begin
  ja.push(Element);
end;

procedure TJSONArray.Add(const Element: TJSONObject);
begin
  ja.push(Element);
end;

procedure TJSONArray.Add(const Element: double);
begin
  ja.push(Element);
end;

procedure TJSONArray.Add(const Element: TJSONPair);
begin
  ja.push(Element);
end;

constructor TJSONArray.Create(AArray: TJSArray);
begin
  inherited Create;
  ja := AArray;
end;

function TJSONArray.GetCount: integer;
begin
  Result := ja.Length;
end;

function TJSONArray.GetEnumerator: TJSONArrayEnumerator;
begin
  Result := TJSONArrayEnumerator.Create(Self);
end;

function TJSONArray.GetItem(index: integer): TJSONValue;
var
  jv: JSValue;
begin
  jv := ja[Index];
  if (jv is TJSONObject) or (jv is TJSONArray) then
    Result := TJSONValue(jv)
  else
    Result := JSONValueFromJS(jv);
end;

function TJSONArray.GetJA: TJSArray;
begin
  if not Assigned(fja) then
    fja := TJSArray.new;

  Result := fja;
end;

procedure TJSONArray.SetItem(index: integer; const Value: TJSONValue);
begin
  if Value is TJSONObject then
    ja[index] := TJSONObject(Value).jo;
end;

function TJSONArray.ToJSON: string;
begin
  Result := ToString;
end;

function TJSONArray.ToString: string;
var
  i: integer;
  jv: TJSONValue;
  comma: string;
begin
  Result := '[';
  comma := '';

  for i := 0 to Count - 1 do
  begin
    jv := Items[i];

    if Assigned(jv) then
    begin
      if (jv is TJSONObject) then
      begin
        Result := Result + comma + (jv as TJSONObject).ToString;
      end
      else
        Result := Result + comma + jv.ToString;

      comma := ',';
    end;
  end;

  Result := Result + ']';
end;

{ TJSONObject }

constructor TJSONObject.Create;
begin
  inherited Create;
  FMembers := TJSONPairList.Create;
  fjo := nil;
end;

{$HINTS OFF}
function TJSONObject.RemovePair(const PairName: string): TJSONPair;
var
  o: TJSObject;
begin
  Result := nil;
  if Assigned(fjo) then
  begin
    o := fjo;
    asm
      delete o[PairName];
    end;
  end;
end;

function TJSONObject.AddPair(const Pair: TJSONPair): TJSONObject;
var
  LObj: TJSONObject;
  o: TJSObject;
begin
  if Assigned(Pair) then
  begin
    AddDescendant(Pair);
    if Assigned(fjo) then
    begin
      o := fjo;
      asm
        o[Pair.fjs.FValue] = Pair.fjv.fjv;
      end;
    end;

    LObj := TJSONObject(ParseJSONValue(ToJSON));
  end;
  Result := LObj;
end;
{$HINTS ON}

function TJSONObject.AddPair(const Str: string;
  const Val: TJSONValue): TJSONObject;
begin
  AddPair(TJSONPair.Create(Str, Val));
  Result := Self;
end;

procedure TJSONObject.AddDescendant(const Descendent: TJSONPair);
begin
  FMembers.Add(Descendent);
end;

function TJSONObject.AddPair(const Str, Val: string): TJSONObject;
begin
  AddPair(TJSONPair.Create(Str, Val));
  Result := Self;
end;

function TJSONObject.AddPair(const Str: string; const Val: Integer): TJSONObject;
begin
  AddPair(TJSONPair.Create(Str, TJSONNumber.Create(Val)));
  Result := Self;
end;

function TJSONObject.AddPair(const Str: string; const Val: Int64): TJSONObject;
begin
  AddPair(TJSONPair.Create(Str, TJSONNumber.Create(Val)));
  Result := Self;
end;

function TJSONObject.AddPair(const Str: string; const Val: Double): TJSONObject;
begin
  AddPair(TJSONPair.Create(Str, TJSONNumber.Create(Val)));
  Result := Self;
end;

constructor TJSONObject.Create(AObject: TJSObject);
begin
  inherited Create;
  FMembers := TJSONPairList.Create;
  jo := AObject;
end;

destructor TJSONObject.Destroy;
begin
  FMembers.Free;
  inherited Destroy;
end;

function TJSONObject.Get(Index: Integer): TJSONPair;
begin
  Result := Get(TJSObject.keys(jo)[Index]);
end;

function TJSONObject.Get(Name: string): TJSONPair;
var
  jv: JSValue;
  jsv: TJSONValue;
  i: integer;
  p: TJSONPair;
begin
  Result := nil;

  if Assigned(jo) then
  begin
    jv := jo.Properties[Name];
    if (jv <> Undefined) or (TJSObject(jv) = nil) then    
    begin
      Result := TJSONPair.Create;
      jsv := JSONValueFromJS(jv);
      Result.fjo := jo;
      Result.JsonString.Value := Name;
      Result.JsonValue := jsv;
    end;
  end
  else
  begin
    for i := 0 to FMembers.Count - 1 do
    begin
      p := TJSONPair(FMembers.Items[i]);
      if (p.fjs.ToString = '"'+Name+'"') then
        Result := p;
    end;
  end;
end;

function TJSONObject.GetCount: Integer;
begin
  if Assigned(jo) then
    Result := Length(TJSObject.keys(jo))
  else
    if Assigned(FMembers) then
      Result := FMembers.Count
    else
      Result := 0;
end;

function TJSONObject.GetItem(const Index: Integer): TJSONValue;
var
  jv: JSValue;
begin
  jv := jo[TJSObject.keys(jo)[Index]];
  Result := JSONValueFromJS(jv);
end;

function TJSONObject.GetJSONValue(Name: string): string;
var
  jv: JSValue;
begin
  jv :=  jo.Properties[Name];
  Result := JSONObjectToString(jv);
end;

function TJSONObject.GetPair(const I: Integer): TJSONPair;
var
  v: TJSONValue;
  l: integer;
  s: string;
  jv: JSValue;
begin
  if (FMembers.Count = 0) and  Assigned(jo) and (length(TJSObject.keys(jo)) > 0) then
  begin
    l := length(TJSObject.keys(jo));
    if I < l then
    begin
      jv := jo[TJSObject.keys(jo)[I]];
      v := JSONValueFromJS(jv);
      s := TJSObject.keys(jo)[I];
      Result := TJSONPair.Create(s,v);
      Exit;
    end;
  end;

  if (I >= 0) and (I < FMembers.Count) then
    Result := TJSONPair(FMembers[I])
  else
    Result := nil;
end;

function TJSONObject.GetValue(const Name: string): TJSONValue;
var
  jp: TJSONPair;
begin
  Result := nil;

  jp := Get(Name);

  if Assigned(jp) then
  begin
    Result := jp.JsonValue;
    Result.fjo := jp.fjo;
  end;
end;

class function TJSONValue.JSONValueFromJS(const AJSValue: JSValue): TJSONValue;
var
  d: double;
begin
  Result := nil;

  if isArray(AJSValue) then
  begin
    Result := TJSONArray.Create;
    (Result as TJSONArray).ja := TJSArray(AJSValue);
  end
  else
  if isObject(AJSValue) then
  begin
    Result := TJSONObject.Create;
    (Result as TJSONObject).jo := TJSObject(AJSValue);
  end
  else
  if isString(AJSValue) then
  begin
    Result := TJSONString.Create;
    (Result as TJSONString).Value := string(AJSValue);
  end
  else
  if isNumber(AJSValue) then
  begin
    Result := TJSONNumber.Create;
    (Result as TJSONNumber).Value := string(AJSValue);
    d := double(AJSValue);
    (Result as TJSONNumber).AsDouble := d;
    if Frac(d) = 0 then
      (Result as TJSONNumber).AsInt := integer(AJSValue);
  end
  else
  if isNull(AJSValue) then
    Result := TJSONNull.Create
  else
  if isBoolean(AJSValue) then
  begin
    if toBoolean(AJSValue) then
      Result := TJSONTrue.Create
    else
      Result := TJSONFalse.Create;
  end;

  if not Assigned(Result) then
    Result := TJSONNull.Create;

  Result.jv := AJSValue;
end;

class function TJSONObject.ParseJSONValue(const data: string): TJSONValue;
var
  O: TJSObject;
begin
  O := TJSJSON.parseObject(data);
  Result := JSONValueFromJS(O);
end;

{$HINTS OFF}
procedure TJSONObject.SetValue(const Name: string; AValue: string);
var
  O: TJSObject;
begin
  O := JSObject;
  asm
    O[Name] = AValue;
  end;
end;

procedure TJSONObject.SetValue(const Name: string; AValue: integer);
var
  O: TJSObject;
begin
  O := JSObject;
  asm
    O[Name] = AValue;
  end;
end;

procedure TJSONObject.SetValue(const Name: string; AValue: double);
var
  O: TJSObject;
begin
  O := JSObject;
  asm
    O[Name] = AValue;
  end;
end;

procedure TJSONObject.SetValues(const Name: string; const Value: TJSONValue);
var
  O: TJSObject;
begin
  O := JSObject;
  asm
    O[Name] = Value;
  end;
end;
{$HINTS ON}

function TJSONObject.ToJSON: string;
var
  s: string;
  Size: Integer;
  I: Integer;
  jp: TJSONPair;
begin
  if Assigned(jo) then
    Size := Count
  else
    Size := FMembers.Count;

  s := '{';
  if Size > 0 then
  begin
    if Assigned(jo) then
    begin
      jp := TJSONPair(Get(0));
      if Assigned(jp) then
        s := s + jp.ToString
      else
        s := s + '"' + TJSObject.keys(jo)[0] +'":null';
    end
    else
      s := s + TJSONPair(FMembers[0]).ToString;
  end;

  for I := 1 to Size - 1 do
  begin
    if Assigned(jo) then
    begin
      jp := TJSONPair(Get(I));
      if Assigned(jp) then
        s := s + ',' + jp.ToString
      else
        s := s + ',' + '"' + TJSObject.keys(jo)[I] +'":null';
    end
    else
      s := s + ',' + TJSONPair(FMembers[I]).ToString;
  end;
  s := s + '}';
  Result := s;
end;

function TJSONObject.ToString: string;
begin
  Result := ToJSON;
end;

{ TJSONPair }

constructor TJSONPair.Create;
begin
  inherited;
  fjs := TJSONString.Create;
end;

constructor TJSONPair.Create(const Str: string; const Value: TJSONValue);
begin
  inherited Create;
  JsonString := TJSONString.Create(Str);
  JsonValue := Value;
end;

constructor TJSONPair.Create(const Str, Value: string);
begin
  inherited Create;
  JsonString := TJSONString.Create(Str);
  JsonValue := TJSONString.Create(Value);
end;

destructor TJSONPair.Destroy;
begin
  fjs.Free;
  inherited;
end;

function TJSONPair.ToString: string;
begin
  if (JsonValue is TJSONObject) then
    Result := '"' + JsonString.GetStrValue + '":' + (JsonValue as TJSONObject).ToJSON
  else
  begin
    if (JsonString <> nil) and (JsonValue <> nil) then
      Result := '"' + JsonString.GetStrValue + '":' + JsonValue.ToString
    else
      Result := '';
  end;
end;

{ TJSONAncestor }

function TJSONAncestor.GetStrValue: string;
begin
  Result := '';
end;

procedure TJSONAncestor.SetStrValue(const Value: string);
begin
  //
end;

{ TJSONString }

constructor TJSONString.Create(AString: string);
begin
  inherited Create;
  jv := AString;
  FValue := AString;
end;

function TJSONString.GetStrValue: string;
begin
  Result := FValue;
end;

procedure TJSONString.SetStrValue(const Value: string);
begin
  FValue := Value;
  jv := Value;

  if Assigned(fjo) then
  begin
    asm
      var s = Object.keys(this.fjo)[0];
      this.fjo[s] = Value;
    end;
  end;
end;

{ TJSONNumber }

constructor TJSONNumber.Create(ANumber: integer);
begin
  inherited Create;
  jv := ANumber;
end;

constructor TJSONNumber.Create(ANumber: Int64);
begin
  inherited Create;
  jv := ANumber;
end;

constructor TJSONNumber.Create(ANumber: double);
begin
  inherited Create;
  jv := ANumber;
end;

function TJSONNumber.GetStrValue: string;
begin
  Result := FloatToStr(FDouble, TFormatSettings.Invariant);
end;

procedure TJSONNumber.SetStrValue(const Value: string);
begin
  FDouble := StrToFloat(Value, TFormatSettings.Invariant);
  if Frac(FDouble) = 0 then
  begin
    if not TryStrToInt64(Value, FInt64, TFormatSettings.Invariant) then
      FInt64 := 0;
    FInt := FInt64;
    jv := FInt64;
  end
  else
    jv := FDouble;
end;

{ TJSONTrue }

function TJSONTrue.GetStrValue: string;
begin
  Result := 'true';
end;


function TJSONTrue.ToString: string;
begin
  Result := GetStrValue;
end;

{ TJSONBool }


procedure TJSONBool.SetStrValue(const Value: string);
var
  s: string;
begin
  s := Uppercase(Value);
  FBool := (s = 'TRUE');
end;


{ TJSONFalse }

function TJSONFalse.GetStrValue: string;
begin
  Result := 'false';
end;

function TJSONFalse.ToString: string;
begin
  Result := GetStrValue;
end;

{ TJSONNull }

function TJSONNull.GetStrValue: string;
begin
  Result := 'null';
end;


function TJSONNull.ToString: string;
begin
  Result := GetStrValue;
end;

{ TJSONValue }

constructor TJSONValue.Create(AJSValue: JSValue);
begin
  inherited Create;
  jv := AJSValue;
end;

function TJSONValue.ToString: string;
begin
  Result := TJSJSON.stringify(jv);
end;

function TJSONValue.GetStrValue: string;
begin
  Result := string(jv);
end;


function TJSONValue.GetValue<T>(const APath: string = ''): T;
begin
  Result := nil;
end;

function TJSONValue.GetJSValue: JSValue;
begin
  Result := jv;
end;


{ TJSONArrayEnumerator }

constructor TJSONArrayEnumerator.Create(AJSONArray: TJSONArray);
begin
  inherited Create;
  FJSONArray := AJSONArray;
  FPosition := -1;
end;

function TJSONArrayEnumerator.GetCurrent: TJSONValue;
begin
  Result := FJSONArray[FPosition];
end;

function TJSONArrayEnumerator.MoveNext: Boolean;
begin
  Inc(FPosition);
  Result := FPosition < FJSONArray.Count;
end;


end.
