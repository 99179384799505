program ActoriousClient;

{$R *.dres}

uses
  Vcl.Forms,
  WEBLib.Forms,
  Main in 'Main.pas';

{$R *.res}

begin
  Application.Initialize;
  Application.MainFormOnTaskbar := True;
  Application.CreateFormDirect(TMainForm, MainForm);
  Application.Run;
end.
